import React, { useEffect, useState } from "react";
import SingleSubCategoryHero from "./components/hero/SingleSubCategoryHero";
import AxiosInstance from "../../../axiosInstance";
import { useParams } from "react-router";
import SingleCategoryDetails from "./components/details/SingleCategoryDetails";
import { Helmet } from "react-helmet-async";
import AddToCart from "../../../components/frontend/cart/AddToCart";

const SingleSubCategory = () => {
  const subCategoryName = useParams().sub_category_name;
  const [Data, setData] = useState("");
  useEffect(() => {
    AxiosInstance.get(`/display/sub-category/${subCategoryName}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [showAddToCard, setshowAddToCard] = useState(false);
  const handleShowAddtoCart = () => {
    setshowAddToCard(!showAddToCard);
  };
  const handleClose = () => {
    setshowAddToCard(false);
    sessionStorage.removeItem("selectedServices");
  };
  return (
    <>
      <Helmet>
        <meta name="title" content={Data.sub_category_name || ""} />
        <title>{`Nirapod.xyz | ${Data.sub_category_name || ""}`}</title>
      </Helmet>

      {showAddToCard && (
        <AddToCart
          handleShowAddtoCart={handleShowAddtoCart}
          handleClose={handleClose}
          Data={Data}
        />
      )}
      <div className="w-full h-full ">
        <div>
          {" "}
          <SingleSubCategoryHero Data={Data} />
        </div>
        <div>
          {" "}
          <SingleCategoryDetails
            handleShowAddtoCart={handleShowAddtoCart}
            Data={Data}
          />
        </div>
      </div>
    </>
  );
};

export default SingleSubCategory;
