import FrontendLayout from "../../layout/frontend/Index";
import AllServices from "../../view/frontend/all-services/AllServices";
import Checkout from "../../view/frontend/checkout/Checkout";
import SingleBlog from "../../view/frontend/single-blog/SingleBlog";
import SingleSubCategory from "../../view/frontend/single-sub-category/SingleSubCategory";
import Home from "../../view/home/Index";

const FrontendRouter = {
  path: "/",
  element: <FrontendLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/:sub_category_name",
      element: <SingleSubCategory />,
    },
    {
      path: "/checkout",
      element: <Checkout />,
    },
    {
      path: "/blog/:blog_title/:id",
      element: <SingleBlog />,
    },
    {
      path: "/all-services",
      element: <AllServices />,
    },
  ],
};

export default FrontendRouter;
