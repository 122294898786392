import React, { useState } from "react";
import { BiMinus, BiPlus } from "react-icons/bi";

const ServiceQuantity = ({ Data }) => {
  const [quantities, setQuantities] = useState(
    Data.services.map(() => 0) // Initialize quantities for each service
  );
  const [formData, setFormData] = useState([]);

  const updateFormData = (index, quantity) => {
    const service = Data.services[index]; // Get the service details at this index
    const updatedServiceData = {
      service_id: service.id,
      service_name: service.service_name,
      quantity: quantity,
      price: service.price,
      sub_cat_id: service.sub_cat_id,
    };

    // Update or add the service data in the formData array
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      const existingServiceIndex = updatedFormData.findIndex(
        (item) => item.service_name === service.service_name
      );

      if (existingServiceIndex !== -1) {
        // Update existing service
        updatedFormData[existingServiceIndex] = updatedServiceData;
      } else {
        // Add new service
        updatedFormData.push(updatedServiceData);
      }

      // Store updated data in sessionStorage
      sessionStorage.setItem(
        "selectedServices",
        JSON.stringify(updatedFormData)
      );

      return updatedFormData;
    });
  };

  const handleIncrement = (index) => {
    const newQuantities = [...quantities];
    newQuantities[index] += 1;
    setQuantities(newQuantities);

    // Update form data with new quantity and store in sessionStorage
    updateFormData(index, newQuantities[index]);
  };

  const handleDecrement = (index) => {
    const newQuantities = [...quantities];
    if (newQuantities[index] > 0) {
      newQuantities[index] -= 1;
      setQuantities(newQuantities);

      if (newQuantities[index] === 0) {
        // Remove the item from formData and update sessionStorage
        setFormData((prevFormData) => {
          const updatedFormData = prevFormData.filter(
            (item) => item.service_name !== Data.services[index].service_name
          );

          // Update sessionStorage
          sessionStorage.setItem(
            "selectedServices",
            JSON.stringify(updatedFormData)
          );

          return updatedFormData;
        });
      } else {
        // Update form data with new quantity and store in sessionStorage
        updateFormData(index, newQuantities[index]);
      }
    }
  };

  return (
    <div className="">
      {Data.services.length > 0 ? (
        <div>
          {Data.services.map((items, index) => {
            return (
              <div key={index} className="border-b border-0.5 py-3 ">
                <div className="flex justify-between items-center">
                  <div className="flex items-start justify-start gap-3">
                    <div className="w-[50px] h-[50px]">
                      <img
                        className="w-full h-full object-cover"
                        src={items.serviceImageUrl}
                        alt={items.service_name}
                      />
                    </div>
                    <div>
                      <p className="text-lg font-semibold">
                        {items.service_name}
                      </p>
                      {items.highlighted_one && (
                        <p className="text-xs text-black/50">
                          {items.highlighted_one}
                        </p>
                      )}
                      {items.highlighted_two && (
                        <p className="text-xs text-black/50">
                          {items.highlighted_two}
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() => handleDecrement(index)}
                        className="flex justify-center items-center bg-yellow-600 text-white w-[30px] h-[30px] cursor-pointer rounded-lg"
                      >
                        <BiMinus className="text-3xl" />
                      </div>
                      <input
                        type="number"
                        value={quantities[index]}
                        className="border w-[30px] h-[30px] focus:outline-none text-lg text-black/70 text-center font-semibold"
                        readOnly
                      />
                      <div
                        onClick={() => handleIncrement(index)}
                        className="flex justify-center items-center bg-yellow-600 text-white w-[30px] h-[30px] cursor-pointer rounded-lg"
                      >
                        <BiPlus className="text-3xl" />
                      </div>
                    </div>
                    <p className="text-md text-green-800 text-center mt-3 font-semibold">
                      ৳{items.price}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p>No Services Found</p>
      )}
    </div>
  );
};

export default ServiceQuantity;
