import React, { useEffect, useState, useRef } from "react";
import AxiosInstance from "../../../axiosInstance";
import Container from "../../../utils/Container";
import { Link } from "react-router-dom";

const AllServices = () => {
  const [Data, setData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const subCategoryRefs = useRef({}); // Storing refs for all sub-category sections

  useEffect(() => {
    AxiosInstance.get("/category/all-category")
      .then((res) => {
        setData(res.data);
        if (res.data.length > 0) {
          setSelectedCategory(res.data[0]); // Default to first category
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    // Scroll to the corresponding sub-category section
    if (subCategoryRefs.current[category.id]) {
      subCategoryRefs.current[category.id].scrollIntoView({
        behavior: "smooth",
        block: "center", // Align the section to the center
      });
    }
  };

  return (
    <Container>
      <div className="mt-10 mb-5">
        <div className="flex gap-5">
          <div className="w-[200px] h-auto fixed top-10 border-r p-2 bg-white mt-20">
            <h2 className="text-lg font-bold mb-4">All Categories</h2>
            <div className="space-y-2">
              {Data &&
                Data.map((category, index) => (
                  <div
                    key={index}
                    onClick={() => handleCategorySelect(category)}
                    className={`cursor-pointer p-2 rounded-md ${
                      selectedCategory?.id === category.id
                        ? "bg-gray-200 font-semibold"
                        : "bg-white hover:bg-gray-100"
                    }`}
                  >
                    {category.category_name}
                  </div>
                ))}
            </div>
          </div>

          <div className="flex-1 h-screen overflow-y-auto ml-[15rem]">
            {Data.map((category) => (
              <div
                key={category.id}
                ref={(el) => (subCategoryRefs.current[category.id] = el)}
                className="mb-10"
              >
                <h3 className="text-lg font-semibold mb-3">
                  {category.category_name}
                </h3>
                <div className="flex gap-4 flex-wrap">
                  {category.sub_categories?.length > 0 ? (
                    category.sub_categories.map((subCategory, subIndex) => (
                      <div key={subIndex} className="w-[200px] h-[150px] mb-10">
                        <Link to={`/${subCategory.sub_category_name}`}>
                          <div className="w-full h-full overflow-hidden rounded-lg shadow-md border">
                            <img
                              className="w-full h-full object-cover"
                              src={subCategory.subCatBannerUrl}
                              alt={subCategory.sub_category_name}
                            />
                          </div>
                        </Link>
                        <div className="text-center mt-2 font-bold">
                          {subCategory.sub_category_name}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="">No sub-categories available</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AllServices;
