import React, { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import ServiceQuantity from "./components/ServiceQuanity/ServiceQuantity";
import CartDetails from "./components/cartDetails/CartDetails";
import Login from "../auth/login/Login";

const AddToCart = ({ handleClose, Data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ShowLogin, setShowLogin] = useState(false);
  const getToken = sessionStorage.getItem("accessToken");
  const handleCheckout = () => {
    if (getToken) {
      setIsLoading(true);
      setTimeout(() => {
        window.location.href = "/checkout";
        setIsLoading(false);
      }, 3000);
    } else {
      setIsLoading(true);
      setTimeout(() => {
        setShowLogin(true);
      }, 3000);
    }
  };
  const handleCancel = () => {
    setShowLogin(false);
    setIsLoading(false);
  };
  useEffect(() => {
    // Disable scroll and smooth scroll to the top when the modal opens
    const disableScroll = () => {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
      document.body.style.touchAction = "none";
    };

    const enableScroll = () => {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
      document.body.style.touchAction = "";
    };

    disableScroll();

    // Smooth scroll to the top
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    return () => enableScroll(); // Re-enable scrolling when the modal closes
  }, []);
  const [data, setData] = useState(() => {
    const savedData = sessionStorage.getItem("selectedServices");
    return savedData ? JSON.parse(savedData) : [];
  });
  // Function to fetch and update data from sessionStorage
  const updateData = () => {
    const storedData = sessionStorage.getItem("selectedServices");
    if (storedData) {
      try {
        setData(JSON.parse(storedData));
      } catch (error) {
        console.error("Failed to parse sessionStorage data:", error);
      }
    } else {
      setData([]); // Handle case when there is no data
    }
  };

  useEffect(() => {
    // Initial fetch of sessionStorage data
    updateData();

    // Set up an event listener for `storage` events from other tabs or windows
    const handleStorageEvent = (event) => {
      if (event.key === "selectedServices") {
        updateData(); // Update data if "selectedServices" is modified
      }
    };

    window.addEventListener("storage", handleStorageEvent);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, []);

  useEffect(() => {
    const handleSessionStorageChange = (event) => {
      if (event.key === "selectedServices") {
        updateData();
      }
    };

    // Override sessionStorage.setItem to detect changes made programmatically in the same tab
    const originalSetItem = sessionStorage.setItem;
    sessionStorage.setItem = function (key, value) {
      const event = new Event("storage"); // Manually create a storage event
      event.key = key;
      event.newValue = value;
      document.dispatchEvent(event);
      originalSetItem.apply(this, arguments); // Call the original setItem method
    };

    // Listen for manual storage changes
    document.addEventListener("storage", handleSessionStorageChange);

    return () => {
      document.removeEventListener("storage", handleSessionStorageChange);
      sessionStorage.setItem = originalSetItem; // Restore original setItem
    };
  }, []);
  const calculateSubTotal = () => {
    return data.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0); // Start with total = 0
  };

  const subTotal = calculateSubTotal();
  return (
    <>
      <div className="absolute top-0 left-0 z-[999] ">
        {ShowLogin && <Login handleCancel={handleCancel} />}
      </div>
      <div className="absolute top-0 left-0 w-full h-full bg-black/20 flex justify-center items-center z-50">
        <div className="bg-white relative rounded-md shadow-md w-[850px] min-h-[630px] md:min-h-[450px] transform transition-all duration-300 ease-out scale-95">
          <div className="w-full h-full px-4 py-3 text-lg font-semibold bg-white shadow-md rounded-lg text-center">
            Add Your Desired Services
          </div>
          <button
            onClick={handleClose}
            className="absolute -top-4 text-3xl -right-3 bg-white rounded-full text-rose-600 cursor-pointer"
          >
            <MdCancel />
          </button>
          <div className="flex justify-between flex-col md:flex-row mt-3">
            <div className="h-[450px] overflow-y-scroll w-full md:w-1/2 border-r px-4">
              <ServiceQuantity Data={Data} />
            </div>
            <div className="fixed bottom-0 w-full block md:hidden">
              <div className="flex justify-between items-center px-4 py-2 border-t ">
                <p className="text-md font-semibold">Sub Total:</p>
                <p className="text-md font-semibold">৳{subTotal}</p>
              </div>
              <button
                onClick={handleCheckout}
                className="bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] text-white text-center font-semibold p-4 w-full"
              >
                {isLoading ? "Loading..." : "Checkout"}
              </button>
            </div>
            <div className="h-[450px] w-full md:w-1/2 hidden md:block">
              <CartDetails
                subTotal={subTotal}
                updateData={updateData}
                data={data}
                isLoading={isLoading}
                handleCheckout={handleCheckout}
                Data={Data}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddToCart;
