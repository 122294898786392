import React from "react";

const Cards = () => {
  return (
    <div>
      <div className="grid grid-cols-4 gap-x-5">
        <div className=" w-[350px] h-[120px] rounded-2xl p-4 shadow-md">
          sdasd
        </div>
        <div className=" w-[350px] h-[120px] rounded-2xl p-4 shadow-md">
          sdasd
        </div>
        <div className=" w-[350px] h-[120px] rounded-2xl p-4 shadow-md">
          sdasd
        </div>
        <div className=" w-[350px] h-[120px] rounded-2xl p-4 shadow-md">
          sdasd
        </div>
      </div>
    </div>
  );
};

export default Cards;
