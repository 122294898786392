import React from "react";
import Container from "../../../../../utils/Container";
import SingleCategoryServicesFloating from "../services-float/SingleCategoryServicesFloating";

const SingleCategoryDetails = ({ Data, handleShowAddtoCart }) => {
  return (
    <>
      <div className="mt-0 md:mt-5 ">
        <div>
          <Container>
            <SingleCategoryServicesFloating
              Data={Data}
              handleShowAddtoCart={handleShowAddtoCart}
            />

            <div
              className="mt-5"
              dangerouslySetInnerHTML={{ __html: Data.details }}
            />
          </Container>
        </div>
      </div>
    </>
  );
};

export default SingleCategoryDetails;
