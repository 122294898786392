import React, { useState } from "react";

import { FaArrowCircleRight } from "react-icons/fa";
import Container from "../../../../../utils/Container";

const SingleCategoryServicesFloating = ({ Data, handleShowAddtoCart }) => {
  return (
    <>
      <Container>
        <div className="w-[350px] h-auto md:w-[400px] md:min-h-auto px-7 py-7 bg-red-500 relative md:fixed -top-0 left-[50%] transform -translate-x-[50%] md:top-[20%] 2xl:top-[20%] xl:top-[20%] md:left-auto md:-right-[22%] lg:right-0 xl:-right-[10%] 2xl:right-0 rounded-lg bg-gradient-to-t from-[#2ad7b3] to-[#077cb8] shadow-md z-0 md:z-30">
          <p className="text-white text-xl md:text-2xl font-semibold mb-8">
            {Data.sub_category_name}
          </p>
          <div>
            {Data && Data.services.length > 0 ? (
              <>
                {Data.services.map((items, index) => {
                  return (
                    <div
                      onClick={handleShowAddtoCart}
                      key={index.id}
                      className="my-5 bg-white px-4 py-2 rounded-2xl shadow-lg duration-300 scale-100 hover:scale-105 cursor-pointer"
                    >
                      <div className="flex justify-between items-center">
                        <div>
                          <p className="text-black/80 text-sm md:text-lg font-semibold px-3">
                            {items.service_name}
                          </p>
                        </div>
                        <div>
                          <FaArrowCircleRight className="text-blue-600" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <p className="text-white text-sm">No Services Found</p>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default SingleCategoryServicesFloating;
