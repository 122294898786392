import React, { useEffect, useState } from "react";
import Login from "../../../auth/login/Login";
import { FaMinusCircle } from "react-icons/fa";

const CartDetails = ({
  handleCheckout,
  isLoading,
  updateData,
  data,
  subTotal,
}) => {
  const handleRemove = (service_name) => {
    // Step 1: Get the selectedServices from sessionStorage
    const selectedServices =
      JSON.parse(sessionStorage.getItem("selectedServices")) || [];

    // Step 2: Filter the array to remove the service with the matching service_name
    const updatedServices = selectedServices.filter(
      (service) => service.service_name !== service_name
    );

    // Step 3: Update the sessionStorage with the updated selectedServices
    sessionStorage.setItem("selectedServices", JSON.stringify(updatedServices));
    updateData();
  };

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <div className="flex-1 h-[400px] overflow-y-scroll px-4 py-3">
          {data.length > 0 ? (
            <div>
              {data.map((item, index) => (
                <div
                  key={index}
                  className="relative flex justify-between items-center gap-3 mb-5 bg-slate-200/50 px-2 py-2 rounded-lg"
                >
                  <span
                    onClick={() => handleRemove(item.service_name)} // Use an anonymous function to avoid immediate execution
                    className="absolute -top-2 -right-2 text-red-600"
                  >
                    <FaMinusCircle />
                  </span>
                  <div>
                    <p className="text-md font-medium">{item.service_name}</p>
                    <p>Quantity: {item.quantity}</p>
                  </div>
                  <div>
                    <p className="text-md text-green-800 text-center font-semibold">
                      ৳{item.price}.00
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No services selected</p>
          )}
        </div>
        <div className="">
          <div className="flex justify-between items-center px-4 py-2 border-t ">
            <p className="text-md font-semibold">Sub Total:</p>
            <p className="text-md font-semibold">৳{subTotal}</p>
          </div>

          <div className="mt-4">
            <button
              onClick={handleCheckout}
              className="bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] text-white text-center font-semibold p-4 w-full"
            >
              {isLoading ? "Loading..." : "Checkout"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartDetails;
